<template>
  <v-main>
    <v-row>
      <v-col cols="12" sm="10" offset-sm="1">
        <v-card>
          <v-container fluid>
            <v-row>
              <v-col
                v-for="n in student_list"
                :key="n.ID"
                class="d-flex child-flex"
                cols="3"
              >
                <v-card flat tile class="d-flex">
                  <v-img
                    :src="n.avatar"
                    aspect-ratio="1"
                    class="grey lighten-2"
                    @click="clickMe(n)"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { studentList } from "@/api";

export default {
  data() {
    return {
      student_list: [],
    };
  },
  mounted() {
    this.getStudentList();
  },
  methods: {
    clickMe(n) {
      this.$router.push({
        name: "sign",
        query: { id: n.ID, nick_name: n.nick_name },
      });
    },
    getStudentList() {
      studentList({}).then((res) => {
        this.student_list = res.data.data;
      });
    },
  },
};
</script>
